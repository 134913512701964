import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import { selectModalViewModel } from '../store/feature/feature.selectors';
import { JobComponent } from '../job/job.component';
import { modalComponentActions } from '../store/feature/feature.actions';

@Component({
  selector: 'dashboard-feature-job-modal',
  standalone: true,
  imports: [CommonModule, JobComponent],
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  viewModel$ = this.store.select(selectModalViewModel);

  constructor(
    private dialogRef: DialogRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(modalComponentActions.init());
  }

  onClose() {
    this.dialogRef.close();
  }
}
