import { importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideClient } from '@sites/data-connect';
import { ShareService } from '@sites/data-hmm/hmm-authz';
import { ShareEffects } from './store.effects';
import { ShareKey, shareReducer } from './store.reducer';

export const SHARE_STORE_PROVIDERS = [
  provideClient(ShareService),
  importProvidersFrom(
    StoreModule.forFeature(ShareKey, shareReducer),
    EffectsModule.forFeature([ShareEffects])
  ),
];
