@if (viewModel$ | async; as vm) {
  <div class="tw-bg-white tw-text-sm">
    <div
      class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-slate-4 tw-p-4"
    >
      <div class="tw-text-base tw-font-bold">Jobs</div>
      <button
        (click)="onClose()"
        type="button"
        class="tw-text-night-3 hover:tw-text-night"
      >
        <span class="bi bi-x-lg"></span>
        <span class="tw-sr-only">Close</span>
      </button>
    </div>
    <div class="tw-max-h-96 tw-overflow-y-auto tw-bg-cloud-4">
      <div class="tw-flex tw-flex-col tw-divide-y tw-divide-slate-2">
        @for (job of vm.jobs; track job.workflowId) {
          <div class="tw-p-4">
            <dashboard-feature-job-job [job]="job"></dashboard-feature-job-job>
          </div>
        }
      </div>
    </div>
  </div>
}
