import { Inject, Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AuthService } from '@sites/data-auth';
import { ObservableClient } from '@sites/data-connect';
import {
  AuthzService,
  Grant_Action,
  Grant_Namespace,
} from '@sites/data-hmm/hmm-authz';
import {
  asyncScheduler,
  bufferTime,
  catchError,
  filter,
  map,
  mergeMap,
  observeOn,
  of,
  switchMap,
} from 'rxjs';
import { enterZone, leaveZone } from '../util/zone-scheduler.util';
import { authServiceAction } from './auth.actions';
import { selectAuthId } from './auth.reducer';
import { authSelectors } from './auth.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    @Inject(AuthzService)
    private authzService: ObservableClient<typeof AuthzService>,
    private authService: AuthService,
    private store: Store,
    private zone: NgZone
  ) {}

  checkNamespace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authServiceAction.has),
      // Buffer time renders the application unstable
      // So we run the scheduler outside of the zone
      // Buffered over 100ms but maxes at 20 items
      bufferTime(100, null, 20, leaveZone(this.zone, asyncScheduler)),
      // Which returns when the following filter is true
      filter((values) => values.length !== 0),
      observeOn(enterZone(this.zone, asyncScheduler)),
      concatLatestFrom(() => this.store.select(authSelectors.selectEntities)),
      map(([actions, grants]) => {
        return actions.reduce(
          (acc, { namespace, action }) => {
            const id = selectAuthId({ namespace, action });
            const entity = grants[id];
            if (!entity || !entity.objectIds) {
              acc.push({ namespace, action });
            }
            return acc;
          },
          [] as { namespace: Grant_Namespace; action: Grant_Action }[]
        );
      }),
      filter((actions) => actions.length > 0),
      mergeMap((actions) => {
        return this.authzService
          .listGrantObjects({
            grants: actions.map(({ namespace, action }) => ({
              namespace,
              action,
            })),
          })
          .pipe(
            map(({ results }) => {
              return authServiceAction.hasAuthorized({
                auths: results.map(({ grant, objectIds }) => ({
                  namespace: grant?.namespace || Grant_Namespace.UNKNOWN,
                  action: grant?.action || Grant_Action.UNKNOWN,
                  objectIds,
                })),
              });
            }),
            catchError((error: Error) =>
              of(
                authServiceAction.hasError({
                  error,
                })
              )
            )
          );
      })
    );
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authServiceAction.loadUser),
      switchMap(() => {
        return this.authService.user$.pipe(
          map((user) => {
            return authServiceAction.loadUserSuccess({ user });
          })
        );
      })
    );
  });
}
