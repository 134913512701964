import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  GuardResult,
  MaybeAsync,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { selectServiceWorkerViewModel } from './service-worker.selectors';

export function serviceWorkerCheckForUpdate(
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): MaybeAsync<GuardResult> {
  const store = inject(Store);
  const document = inject(DOCUMENT);

  return store.select(selectServiceWorkerViewModel).pipe(
    map((vm) => {
      // If an update is available navigate user to URL, updating the app
      if (vm.hasUpdate && document.defaultView) {
        document.defaultView.location.href = state.url;
        return false;
      }
      return true;
    })
  );
}
