import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { createEffect } from '@ngrx/effects';
import { filter, first, from, interval, map, switchMap } from 'rxjs';
import { serviceWorkerActions } from './service-worker.actions';

@Injectable()
export class ServiceWorkerEffects {
  constructor(
    private updates: SwUpdate,
    private appRef: ApplicationRef
  ) {}

  init$ = createEffect(() => {
    return this.appRef.isStable.pipe(
      first((isStable) => isStable === true),
      filter(() => this.updates.isEnabled),
      switchMap(() => interval(60 * 1000)),
      switchMap(() => from(this.updates.checkForUpdate())),
      map((hasUpdates) => serviceWorkerActions.updates({ hasUpdates }))
    );
  });

  version$ = createEffect(() => {
    return this.updates.versionUpdates.pipe(
      map((event) => serviceWorkerActions.versionEvent({ event }))
    );
  });

  unrecoverable$ = createEffect(() => {
    return this.updates.unrecoverable.pipe(
      map((event) => serviceWorkerActions.unrecoverableStateEvent({ event }))
    );
  });
}
