import { DescMessage, Message } from '@bufbuild/protobuf';
import {
  ConnectError,
  Interceptor,
  StreamRequest,
  StreamResponse,
  UnaryRequest,
  UnaryResponse,
} from '@connectrpc/connect';

let requestId = 0;

export const logInterceptor: Interceptor = (next) => async (req) => {
  const start = Date.now();
  try {
    const res = await next(req);

    // Streams
    if (requestIsStream(req) && responseIsStream(res)) {
      logStreamResponse(start, req, res);
    }

    // Unary
    if (!requestIsStream(req) && !responseIsStream(res)) {
      logUnaryResponse(start, req, res);
    }

    return res;
  } catch (err) {
    errorGroup(getLabel(start, req), [
      () => printRequest(req),
      () => printError(err),
    ]);
    throw err;
  }
};

function requestIsStream(
  res:
    | UnaryRequest<DescMessage, DescMessage>
    | StreamRequest<DescMessage, DescMessage>
): res is StreamRequest<DescMessage, DescMessage> {
  return res.stream === true;
}

function responseIsStream(
  res: UnaryResponse<DescMessage> | StreamResponse<DescMessage>
): res is StreamResponse<DescMessage> {
  return res.stream === true;
}

function logUnaryResponse(
  start: number,
  req: UnaryRequest<DescMessage, DescMessage>,
  res: UnaryResponse<DescMessage>
) {
  group(getLabel(start, req), [
    () => printRequest(req),
    () => printResponse(res),
    () => printMessages(req, res),
  ]);
}

async function* logStreamResponse(
  start: number,
  req: StreamRequest<DescMessage, DescMessage>,
  res: StreamResponse<DescMessage>
) {
  for await (const message of res.message) {
    group(getLabel(start, req), [
      () => printRequest(req),
      () => printResponse(res),
      () => printStreamMessage(req, message),
    ]);
    yield message;
  }
}

function group(name: string, actions: (() => void)[], style?: string) {
  console.groupCollapsed(`%c${name}`, style ? style : 'color: #666666;');
  actions.forEach((action) => action());
  console.groupEnd();
}

function getLabel(
  start: number,
  req:
    | UnaryRequest<DescMessage, DescMessage>
    | StreamRequest<DescMessage, DescMessage>
): string {
  return `${++requestId}: ${Date.now() - start}ms - ${req.service.typeName} - ${
    req.method.name
  }`;
}

function errorGroup(name: string, actions: (() => void)[]) {
  group(name, actions, 'color: #f00505;');
}

function printRequest(
  req:
    | UnaryRequest<DescMessage, DescMessage>
    | StreamRequest<DescMessage, DescMessage>
) {
  group('Request', [() => console.log(req)]);
}

function printResponse(
  res: UnaryResponse<DescMessage> | StreamResponse<DescMessage>
) {
  group('Response', [() => console.log(res)]);
}

function printMessages(
  req: UnaryRequest<DescMessage, DescMessage>,
  res: UnaryResponse<DescMessage>
) {
  group('Messages', [
    () => console.log('Request', JSON.stringify(req.message, null, 2)),
    () => console.log('Response', JSON.stringify(res.message, null, 2)),
  ]);
}

function printStreamMessage(
  req: StreamRequest<DescMessage, DescMessage>,
  message: Message
) {
  group('Messages', [
    () => console.log('Request', JSON.stringify(req.message, null, 2)),
    () => console.log('Response', JSON.stringify(message, null, 2)),
  ]);
}

function printError(err: unknown) {
  group('Error', [
    () => console.log(err),
    () => (err instanceof ConnectError ? console.log({ ...err }) : ''),
  ]);
}
