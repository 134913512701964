@if (viewModel$ | async; as vm) {
  @if (vm.total > 0) {
    <div class="tw-border-t tw-border-night-2 tw-py-2">
      <button
        (click)="onClick()"
        class="tw-btn tw-btn-dark tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-font-sans"
        data-testid="menu-item"
      >
        Jobs
        <div
          class="tw-flex tw-min-h-[20px] tw-min-w-[20px] tw-items-center tw-justify-center tw-rounded-full tw-bg-leaf tw-px-2 tw-text-xs tw-font-semibold tw-text-white"
        >
          {{ vm.total }}
        </div>
      </button>
    </div>
  }
}
