import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from '@sites/dashboard/feature-job';
import { TooltipDirective } from '@sites/dashboard/ui';
import { AuthService, User } from '@sites/data-auth';
import { Subscription } from 'rxjs';
import { MenuItem, MenuService } from './menu.service';

@Component({
  selector: 'dashboard-menu',
  templateUrl: './menu.component.html',
  standalone: true,
  imports: [
    RouterModule,
    TooltipDirective,
    MenuItemComponent,
    TooltipDirective,
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  user?: User;
  menuItems: MenuItem[] = [];
  subscriptions = new Subscription();

  year = new Date().getFullYear();

  constructor(
    private authService: AuthService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.menuService.getItems().subscribe((items) => {
        this.menuItems = items;
      })
    );

    this.subscriptions.add(
      this.authService.user$.subscribe((user) => {
        this.user = user;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }
}
