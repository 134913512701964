import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { OrgService } from '@sites/data-hmm/hmm-orgs';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { brandServiceActions } from './brand.actions';
import { selectAllBrands } from './brand.selectors';

@Injectable()
export class BrandEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(OrgService)
    private organisationService: ObservableClient<typeof OrgService>
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(brandServiceActions.list),
      concatLatestFrom(() => this.store.select(selectAllBrands)),
      filter(([, brands]) => brands.length === 0),
      map(() => brandServiceActions.listRefresh())
    );
  });

  listRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(brandServiceActions.listRefresh),
      mergeMap(() =>
        this.organisationService.listBrands({}).pipe(
          map(({ results }) =>
            brandServiceActions.listSuccess({
              brands: results,
            })
          ),
          catchError((error: Error) =>
            of(brandServiceActions.listFailure({ error }))
          )
        )
      )
    );
  });
}
