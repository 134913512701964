<nav
  class="tw-h-full tw-w-full tw-overflow-y-auto tw-bg-night lg:tw-max-h-[calc(100vh-80px)]"
>
  <div class="tw-py-2">
    <ul class="tw-m-0 tw-list-none tw-p-0" role="menubar">
      @for (item of menuItems; track $index) {
        <li>
          <a
            [routerLink]="item.route"
            [queryParams]="item.params"
            #rla="routerLinkActive"
            role="menuitem"
            class="tw-btn tw-btn-dark tw-w-full tw-font-sans"
            routerLinkActive="tw-font-bold"
            [title]="item.label"
            onclick="this.blur()"
          >
            {{ item.label }}
          </a>
        </li>
      }
    </ul>
  </div>
  <dashboard-feature-job-menu-item> </dashboard-feature-job-menu-item>

  @if (user) {
    <div
      class="tw-flex tw-flex-col tw-gap-2 tw-border-t tw-border-night-2 tw-py-2"
    >
      <button
        (click)="logout()"
        type="button"
        class="tw-btn tw-btn-dark tw-flex tw-w-full tw-justify-between tw-font-sans"
      >
        Sign Out
        <i class="bi bi-box-arrow-right tw-text-lg tw-leading-none"></i>
      </button>
    </div>
    <div
      class="tw-border-t tw-border-night-2 tw-p-4 tw-text-sm tw-text-night-3"
    >
      <div
        [dashboardUiTooltip]="user.email"
        class="tw-overflow-hidden tw-text-ellipsis"
      >
        You are logged in as {{ user.email }}
      </div>
    </div>
  }
  <div class="tw-border-t tw-border-night-2 tw-p-4 tw-text-sm tw-text-night-3">
    &copy;{{ year }} Human Made Machine
    <a
      href="https://www.humanmademachine.com/privacy"
      target="_blank"
      rel="noopener noreferrer"
      class="tw-link tw-text-night-3"
      >Privacy Policy</a
    >
  </div>
</nav>
