import { Inject, Injectable } from '@angular/core';
import { ConnectError } from '@connectrpc/connect';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isDefined } from '@sites/dashboard/util';
import { AuthService } from '@sites/data-auth';
import { ObservableClient } from '@sites/data-connect';
import { Job, JobService, Job_Initiator } from '@sites/data-hmm/hmm-job';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  throttleTime,
} from 'rxjs';
import {
  menuItemComponentActions,
  modalComponentActions,
  panelComponentActions,
} from '../feature/feature.actions';
import { jobServiceActions } from './job.actions';

@Injectable()
export class JobEffects {
  watchAbortController?: AbortController;

  constructor(
    private actions$: Actions,
    @Inject(JobService)
    private jobService: ObservableClient<typeof JobService>,
    private authService: AuthService
  ) {}

  listJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        modalComponentActions.init,
        menuItemComponentActions.init,
        panelComponentActions.init,
        panelComponentActions.refresh,
        jobServiceActions.watchFailure
      ),
      throttleTime(1000),
      switchMap(() => {
        return this.authService.user$.pipe(
          take(1),
          switchMap((user) => {
            return this.jobService
              .list({
                initiator: Job_Initiator.USER,
                owner: user.email,
              })
              .pipe(
                map(({ results }) =>
                  jobServiceActions.listSuccess({ jobs: results })
                ),
                catchError((error: ConnectError) =>
                  of(jobServiceActions.listFailure({ error }))
                )
              );
          })
        );
      })
    );
  });

  watchJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(jobServiceActions.listSuccess),
      // Basic equivalent check, may need upgrading if jobs is used a lot
      distinctUntilChanged(
        (previous, current) => previous.jobs.length === current.jobs.length
      ),
      mergeMap(({ jobs }) => {
        // Nothing to watch
        if (jobs.length === 0) {
          return of();
        }
        // Abort the watch and start a new one
        this.watchAbortController?.abort();
        this.watchAbortController = new AbortController();
        // Watch the current list of jobs
        return this.jobService
          .watch({ jobs }, { signal: this.watchAbortController.signal })
          .pipe(
            // Filter out responses that don't have a job
            map((res) => res.job),
            filter(isDefined<Job>),
            // Trigger success action with the job that's been updated
            map((job) => jobServiceActions.watchSuccess({ job })),
            // Handle any errors
            catchError((error: ConnectError) =>
              of(jobServiceActions.watchFailure({ error }))
            )
          );
      })
    );
  });
}
