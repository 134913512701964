import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { PanelsService } from '@sites/data-hmm/hmm-ninja';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { panelServiceActions } from './panel.actions';
import { selectAllPanels } from './panel.selectors';

@Injectable()
export class PanelEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(PanelsService)
    private panelService: ObservableClient<typeof PanelsService>
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(panelServiceActions.list),
      concatLatestFrom(() => this.store.select(selectAllPanels)),
      filter(([, panels]) => panels.length === 0),
      map(() => panelServiceActions.listRefresh())
    );
  });

  listRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(panelServiceActions.listRefresh),
      mergeMap(() =>
        this.panelService.listPanels({}).pipe(
          map(({ results }) =>
            panelServiceActions.listSuccess({
              panels: results,
            })
          ),
          catchError((error: Error) =>
            of(panelServiceActions.listFailure({ error }))
          )
        )
      )
    );
  });
}
