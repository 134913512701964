import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { CheckService } from '@sites/dashboard/store';
import { AuthService } from '@sites/data-auth';
import { Grant_Action, Grant_Namespace } from '@sites/data-hmm/hmm-authz';
import { Quote_Status } from '@sites/data-hmm/hmm-projects';
import { ErrorService } from '@sites/util-errors';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { MenuComponent } from './menu.component';

export type MenuItem = {
  label: string;
  description: string;
  icon?: string;
  iconUrl?: string;
  route: string[];
  cta: string;
  params?: Params;
};

const MOBILE_BREAKPOINT = 1024;
const MENU_WIDTH_DESKTOP = '240px';
const MENU_HEIGHT_DESKTOP = '80px';
const MENU_WIDTH_MOBILE = '100vw';
const MENU_HEIGHT_MOBILE = '64px';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private showClassification = false;
  private showOrganisations = false;
  private showTracker = false;
  private showPanels = false;
  private showDeveloper = false;
  private showCohorts = false;
  private showStudyList = false;
  private showStudyAdmin = false;
  private showProjects = false;
  private showQuotes = false;
  private showDistributionLink = false;
  private showReconcile = false;
  private showPipeline = false;
  private userIsPurchaser = false;

  private menuItems = new BehaviorSubject<MenuItem[]>([]);

  private subscriptions = new Subscription();

  private ref?: DialogRef<unknown, MenuComponent>;

  constructor(
    private authService: AuthService,
    private checkService: CheckService,
    private errorService: ErrorService,
    private dialog: Dialog,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscriptions.add(this.closeOnNavigation().subscribe());
    this.subscriptions.add(
      this.authService.user$.subscribe(() => {
        this.fetchPermissions().subscribe(() => {
          this.menuItems.next(this.buildMenu());
        });
      })
    );
  }

  toggle() {
    if (this.ref && !this.ref.closed) {
      this.ref.close();
    } else {
      const isMobile =
        (this.document.defaultView?.innerWidth ?? 0) < MOBILE_BREAKPOINT;

      // Make sure the page is scrolled to the top before opening the menu
      this.document.defaultView?.scrollTo({
        top: 0,
        behavior: 'instant',
      });
      this.ref = this.dialog.open(MenuComponent, {
        width: '100%',
        maxWidth: isMobile ? MENU_WIDTH_MOBILE : MENU_WIDTH_DESKTOP,
        height: isMobile ? `calc(100vh - ${MENU_HEIGHT_MOBILE})` : 'auto',
        maxHeight: isMobile ? 'auto' : `calc(100vh - ${MENU_HEIGHT_DESKTOP})`,
        backdropClass: [''],
        autoFocus: false,
        positionStrategy: new GlobalPositionStrategy()
          .top(isMobile ? MENU_HEIGHT_MOBILE : MENU_HEIGHT_DESKTOP)
          .right('0'),
      });
    }
  }

  getItems(): Observable<MenuItem[]> {
    return this.menuItems.asObservable();
  }

  private closeOnNavigation() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap(() => this.ref?.close())
    );
  }

  private fetchPermissions(): Observable<boolean> {
    return forkJoin({
      user: this.authService.user$.pipe(take(1)),
      showClassification: this.checkService.has(
        Grant_Namespace.CLASSIFICATION_SURVEY,
        Grant_Action.UPDATE
      ),
      showOrgs: this.checkService.has(
        Grant_Namespace.ORGS_ORG,
        Grant_Action.UPDATE
      ),
      showPanels: this.checkService.has(
        Grant_Namespace.NINJA_PANELS,
        Grant_Action.CREATE
      ),
      showCohorts: this.checkService.has(
        Grant_Namespace.NINJA_COHORTS,
        Grant_Action.READ
      ),
      showStudyList: this.checkService.has(
        Grant_Namespace.INCUBATOR_RESULT,
        Grant_Action.READ
      ),
      showStudyAdmin: this.checkService.has(
        Grant_Namespace.INCUBATOR_STUDY,
        Grant_Action.UPDATE
      ),
      showStudyAdminSample: this.checkService.has(
        Grant_Namespace.NINJA_PANELS,
        Grant_Action.READ
      ),
      showProjects: this.checkService.has(
        Grant_Namespace.PROJECTS_PROJECT,
        Grant_Action.CREATE
      ),
      showQuotes: this.checkService.has(
        Grant_Namespace.PROJECTS_QUOTE,
        Grant_Action.READ
      ),
      showReconcile: this.checkService.has(
        Grant_Namespace.PROJECTS_FINANCE_REFERENCE,
        Grant_Action.CREATE
      ),
      showPipeline: this.checkService.has(
        Grant_Namespace.PROJECTS_QUOTE,
        Grant_Action.CREATE
      ),
      canUpdateQuote: this.checkService.has(
        Grant_Namespace.PROJECTS_QUOTE,
        Grant_Action.UPDATE
      ),
      canUpdateQuoteStatus: this.checkService.has(
        Grant_Namespace.PROJECTS_QUOTE_STATUS,
        Grant_Action.UPDATE
      ),
    }).pipe(
      map(
        ({
          user,
          showClassification,
          showOrgs,
          showPanels,
          showCohorts,
          showStudyList,
          showStudyAdmin,
          showStudyAdminSample,
          showQuotes,
          showReconcile,
          showProjects,
          showPipeline,
          canUpdateQuote,
          canUpdateQuoteStatus,
        }) => {
          // Admin pages
          if (user.isStaff()) {
            this.showClassification = showClassification;
            this.showOrganisations = showOrgs;
            this.showTracker = showStudyAdmin;
            this.showPanels = showPanels;
            this.showCohorts = showCohorts;
            this.showDistributionLink = showStudyAdmin;
            this.showReconcile = showReconcile;
            this.showProjects = showProjects;
            this.showDeveloper = user.isDeveloper() || user.isProduct();
            this.showPipeline = showPipeline;
            this.userIsPurchaser = canUpdateQuoteStatus && !canUpdateQuote;
          } else {
            // Alert if non-staff user has access to admin pages
            this.alertNonStaffAccess(showClassification, 'Classification');
            this.alertNonStaffAccess(showOrgs, 'Organisations');
            this.alertNonStaffAccess(showPanels, 'Panels');
            this.alertNonStaffAccess(showCohorts, 'Cohorts');
            this.alertNonStaffAccess(showStudyAdmin, 'Study Admin');
            this.alertNonStaffAccess(showReconcile, 'Reconcile');
            this.alertNonStaffAccess(showProjects, 'Projects');
            this.alertNonStaffAccess(showPipeline, 'Pipeline');
          }

          // Panel pages
          this.showStudyAdmin = showStudyAdmin || showStudyAdminSample;

          // Client pages
          this.showQuotes = showQuotes;
          this.showStudyList = showStudyList;

          return true;
        }
      )
    );
  }

  private buildMenu(): MenuItem[] {
    const items: MenuItem[] = [];

    if (this.showOrganisations) {
      items.push({
        label: 'Organisations',
        description: 'Manage brands, users, and groups',
        icon: 'bi-building',
        route: ['/organisations'],
        cta: 'List organisations',
      });
    }

    if (this.showTracker) {
      items.push({
        label: 'Tracker',
        description: 'Track study progress',
        icon: 'bi-bullseye',
        route: ['/tracker'],
        cta: 'Track studies',
      });
    }

    if (this.showStudyAdmin) {
      items.push({
        label: 'Study admin',
        description: 'Configure the study',
        icon: 'bi-wrench',
        route: ['/study-admin'],
        cta: 'Study admin',
      });
    }

    if (this.showClassification) {
      items.push({
        label: 'Classification',
        description: 'Classify recent study comments',
        icon: 'bi-tag',
        route: ['/classification'],
        cta: 'Classify comments',
      });
    }

    if (this.showCohorts) {
      items.push({
        label: 'Cohorts',
        description: 'Manage subject cohorts',
        icon: 'bi-people',
        route: ['/cohorts'],
        cta: 'View cohorts',
      });
    }

    if (this.showPanels) {
      items.push({
        label: 'Panels',
        description: 'See all panels available',
        icon: 'bi-clipboard',
        route: ['/panels'],
        cta: 'View panels',
      });
    }

    if (this.showDistributionLink) {
      items.push({
        label: 'Distribution link',
        description: 'Generate links to distribute between studies',
        icon: 'bi-signpost-split',
        route: ['/distribution-link'],
        cta: 'Distribution link',
      });
    }

    if (this.showProjects) {
      items.push({
        label: 'Projects',
        description: 'Projects',
        icon: 'bi-folder',
        route: ['/project'],
        cta: 'Projects',
      });
    }

    if (this.showQuotes) {
      items.push({
        label: 'Quotes',
        description: 'Quotes',
        icon: 'bi-journal-text',
        route: ['/quote'],
        cta: 'Quotes',
        ...(this.userIsPurchaser && {
          params: {
            status: Quote_Status.READY,
          },
        }),
      });
    }

    if (this.showStudyList) {
      items.push({
        label: 'Studies',
        description: 'View all studies',
        iconUrl:
          'https://assets.humanmademachine.com/hmm/logos/Incubator_Icon_Leaf.svg',
        route: ['/studies'],
        cta: 'View studies',
      });
    }

    if (this.showReconcile) {
      items.push({
        label: 'Reconcile',
        description:
          'Generate Term Reports, link Purchase Orders, and Invoice Studies',
        icon: 'bi-piggy-bank',
        route: ['/reconcile'],
        cta: 'Reconcile Studies',
      });
    }

    if (this.showPipeline) {
      items.push({
        label: 'Pipeline',
        description: 'Upcoming and ongoing work',
        icon: 'bi-bezier2',
        route: ['/pipeline'],
        cta: 'Pipeline',
      });
    }

    if (this.showDeveloper) {
      items.push({
        label: 'Developer',
        description: 'Tools of mayhem',
        icon: 'bi-gear',
        route: ['/developer'],
        cta: 'Developer tools',
      });
    }

    return items;
  }

  private alertNonStaffAccess(permission: boolean, page: string) {
    if (permission) {
      this.errorService.captureError(
        new Error(`Non-staff user had access admin page "${page}"`)
      );
    }
  }
}
