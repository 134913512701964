import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ObservableClient } from '@sites/data-connect';
import { catchError, filter, map, mergeMap, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { OrgService } from '@sites/data-hmm/hmm-orgs';
import { orgServiceActions } from './org.actions';
import { selectAllOrgs } from './org.selectors';

@Injectable()
export class OrgEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(OrgService)
    private organisationService: ObservableClient<typeof OrgService>
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orgServiceActions.list),
      concatLatestFrom(() => this.store.select(selectAllOrgs)),
      filter(([_, orgs]) => orgs.length === 0),
      map(() => orgServiceActions.listRefresh())
    );
  });

  listRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orgServiceActions.listRefresh),
      mergeMap(() =>
        this.organisationService.listOrgs({}).pipe(
          map(({ results }) =>
            orgServiceActions.listSuccess({
              orgs: results,
            })
          ),
          catchError((error: Error) =>
            of(
              orgServiceActions.listFailure({
                error,
              })
            )
          )
        )
      )
    );
  });
}
