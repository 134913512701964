import { importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideClient } from '@sites/data-connect';
import { JobService } from '@sites/data-hmm/hmm-job';
import { JobEffects } from '../job/job.effects';
import { FEATURE_KEY, reducers } from './feature.reducer';

export const JOB_STORE_PROVIDERS = [
  provideClient(JobService),
  importProvidersFrom(
    StoreModule.forFeature(FEATURE_KEY, reducers),
    EffectsModule.forFeature([JobEffects])
  ),
];
