import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { serviceWorkerActions } from './service-worker.actions';

export const ServiceWorkerKey = 'service-worker';

export type ServiceWorkerState = {
  updateAvailable: boolean;
  unrecoverableState: boolean;
};

export const initialServiceWorkerState: ServiceWorkerState = {
  updateAvailable: false,
  unrecoverableState: false,
};

export const serviceWorkerReducer = createReducer(
  initialServiceWorkerState,
  on(serviceWorkerActions.versionEvent, (state, { event }) => {
    return produce(state, (draft) => {
      if (event.type === 'VERSION_READY') {
        draft.updateAvailable = true;
      }
    });
  }),
  on(serviceWorkerActions.unrecoverableStateEvent, (state) => {
    return produce(state, (draft) => {
      draft.unrecoverableState = true;
    });
  })
);
