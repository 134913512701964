import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from '@sites/dashboard/feature-job';
import { TooltipDirective } from '@sites/dashboard/ui';
import { User } from '@sites/data-auth';
import { EnvironmentEnum } from '@sites/util-environment';
import { NavigationService } from '@sites/util-navigation';
import { environment } from '../../environments/environment';
import { MenuItem, MenuService } from '../menu/menu.service';

@Component({
  selector: 'dashboard-default-layout',
  templateUrl: './default-layout.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, MenuItemComponent, TooltipDirective],
})
export class DefaultLayoutComponent {
  isProduction = environment.name === EnvironmentEnum.Production;
  isLocal = environment.name === EnvironmentEnum.Local;
  environment = environment.name;
  loading = false;
  user?: User;
  menuItems: MenuItem[] = [];
  menuExpanded = false;

  showClassification = false;
  showOrganisations = false;
  showTracker = false;
  showPanels = false;
  showSampleManager = false;
  showDeveloper = false;
  showCohorts = false;
  showFinance = false;

  constructor(
    private navigationService: NavigationService,
    private menuService: MenuService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.navigationService.routeLoading$.subscribe(
      (loading) => (this.loading = loading)
    );
  }

  reload() {
    this.document.location.reload();
  }

  toggleMenu() {
    this.menuService.toggle();
  }
}
