import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { FinanceService } from '@sites/data-hmm/hmm-projects';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { financeEntityServiceActions } from './finance-entity.actions';
import { selectAllFinanceEntities } from './finance-entity.selectors';

@Injectable()
export class FinanceEntityEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(FinanceService)
    private financeService: ObservableClient<typeof FinanceService>
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(financeEntityServiceActions.list),
      concatLatestFrom(() => this.store.select(selectAllFinanceEntities)),
      filter(([, financeEntities]) => financeEntities.length === 0),
      map(() => financeEntityServiceActions.listRefresh())
    );
  });

  listRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(financeEntityServiceActions.listRefresh),
      mergeMap(() =>
        this.financeService.listFinanceEntities({}).pipe(
          map(({ results }) =>
            financeEntityServiceActions.listSuccess({
              financeEntities: results,
            })
          ),
          catchError((error: Error) =>
            of(financeEntityServiceActions.listFailure({ error }))
          )
        )
      )
    );
  });
}
