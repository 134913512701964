import { Inject, Injectable } from '@angular/core';
import { isMessage } from '@bufbuild/protobuf';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import {
  AuthzService,
  ShareService,
  SubjectSet,
} from '@sites/data-hmm/hmm-authz';
import { Org, OrgService, User, UserSchema } from '@sites/data-hmm/hmm-orgs';
import { catchError, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { buttonActions, effectsActions, modalActions } from './store.actions';
import { ShareSubject } from './store.models';
import { selectShareSubjects } from './store.selectors';

@Injectable()
export class ShareEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(OrgService)
    private organisationsService: ObservableClient<typeof OrgService>,
    @Inject(AuthzService)
    private authzService: ObservableClient<typeof AuthzService>,
    @Inject(ShareService)
    private shareService: ObservableClient<typeof ShareService>
  ) {}

  listShares$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(buttonActions.init),
      mergeMap(({ id, filter }) => {
        return this.shareService
          .listShares({
            filter,
          })
          .pipe(
            map((res) =>
              effectsActions.listSharesSuccess({ id, shares: res.results })
            ),
            catchError((error) =>
              of(effectsActions.listSharesFailure({ id, error }))
            )
          );
      })
    );
  });

  listSubjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(modalActions.init),
      concatLatestFrom(() => this.store.select(selectShareSubjects)),
      switchMap(([, subjects]) => {
        if (subjects && subjects.length > 0) {
          return of(
            effectsActions.listShareSubjectsSuccess({ shareSubjects: subjects })
          );
        }
        return forkJoin({
          organisations: this.organisationsService.listOrgs({}),
          users: this.organisationsService.listUsers({}),
          groups: this.authzService.listSubjectSets({}),
        }).pipe(
          map((res) => {
            const organisations = res.organisations.results || [];
            const organisationNameById =
              this.organisationNameById(organisations);
            const users = res.users.results || [];
            const groups = res.groups.results || [];

            return effectsActions.listShareSubjectsSuccess({
              shareSubjects: [
                ...users.map((user) =>
                  this.createShareSubject(user, organisationNameById)
                ),
                ...groups.map((group) =>
                  this.createShareSubject(group, organisationNameById)
                ),
              ].sort(this.sort),
            });
          }),
          catchError((error) => {
            return of(effectsActions.listShareSubjectsFailure({ error }));
          })
        );
      })
    );
  });

  createShare$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(modalActions.share),
      switchMap(({ id, share }) => {
        return this.shareService
          .createShare({
            object: share.createdWith,
            subjectId: share.subjectId,
          })
          .pipe(
            map(({ share }) => {
              if (!share) {
                return effectsActions.createShareFailure({
                  id,
                  error: new Error('Share not returned'),
                });
              }
              return effectsActions.createShareSuccess({ id, share });
            }),
            catchError((error) => {
              return of(effectsActions.createShareFailure({ id, error }));
            })
          );
      })
    );
  });

  removeShare$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(modalActions.remove),
      switchMap(({ id, share }) => {
        return this.shareService
          .deleteShare({
            share,
          })
          .pipe(
            map(() => {
              return effectsActions.removeShareSuccess({ id, share });
            }),
            catchError((error) => {
              return of(
                effectsActions.removeShareFailure({ id, error, share })
              );
            })
          );
      })
    );
  });

  private sort(a: ShareSubject, b: ShareSubject) {
    // First sort organisations alphabetically
    if (a.orgName !== b.orgName) {
      return a.orgName.localeCompare(b.orgName);
    }
    // Then put groups on top of users
    if (a.type !== b.type) {
      return a.type === 'group' ? -1 : 1;
    }
    // Then order by name alphabetically
    return a.name.localeCompare(b.name);
  }

  private organisationNameById(organisations: Org[]): {
    [key: string]: string;
  } {
    return organisations.reduce(
      (acc, org) => {
        acc[org.id || ''] = org.name || '';
        return acc;
      },
      {} as { [key: string]: string }
    );
  }

  private createShareSubject(
    subject: User | SubjectSet,
    organisationNameById: {
      [key: string]: string;
    }
  ): ShareSubject {
    return {
      name: subject.name,
      id: isMessage(subject, UserSchema) ? subject.email : subject.id,
      orgId: subject.orgId,
      orgName: organisationNameById[subject.orgId] || subject.orgId,
      type: isMessage(subject, UserSchema) ? 'user' : 'group',
    };
  }
}
