import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { PropertiesService } from '@sites/data-hmm/hmm-incubator';
import { catchError, filter, forkJoin, map, of, switchMap } from 'rxjs';
import { propertyServiceActions } from './property.actions';
import { selectAllProperties } from './property.selectors';

@Injectable()
export class PropertyEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(PropertiesService)
    private propertyService: ObservableClient<typeof PropertiesService>
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(propertyServiceActions.list),
      concatLatestFrom(() => this.store.select(selectAllProperties)),
      filter(([, properties]) => properties.length === 0),
      map(() => propertyServiceActions.listRefresh())
    );
  });

  listRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(propertyServiceActions.listRefresh),
      switchMap(() =>
        forkJoin([
          this.propertyService.listProperties({}),
          this.propertyService.listPlacements({}),
        ]).pipe(
          map(([properties, placements]) =>
            propertyServiceActions.listSuccess({
              properties: properties.results,
              themes: properties.themes,
              placements: placements.results,
            })
          ),
          catchError((error: Error) =>
            of(propertyServiceActions.listFailure({ error }))
          )
        )
      )
    );
  });
}
