import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'dashboard-blank-layout',
  templateUrl: './blank-layout.component.html',
  standalone: true,
  imports: [RouterModule],
})
export class BlankLayoutComponent {}
