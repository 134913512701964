import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UtilErrorsModule } from '@sites/util-errors';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

UtilErrorsModule.init({
  environment: environment.name,
  release: environment.release,
  sentryDsn: environment.sentryDsn,
  sentryTracingSampleRate: environment.sentrySampleRate,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
