import { Code, ConnectError, Interceptor } from '@connectrpc/connect';
import * as Sentry from '@sentry/angular';

/**
 * Sentry tracing interceptor
 */
export const connectTracingInterceptorFactory =
  (env: { apiUrl: string }): Interceptor =>
  (next) =>
  async (req) => {
    return await Sentry.startSpan(
      {
        op: 'http.client',
        name: `POST ${env.apiUrl}/${req.service.typeName}/${req.method.name}`,
      },
      async (span) => {
        // Use standard HTTP attributes to fit in with Sentry
        const parsedURL = new URL(
          `${req.service.typeName}/${req.method.name}`,
          env.apiUrl
        );
        span.setAttribute('http.request.method', 'POST');
        span.setAttribute('server.address', parsedURL.hostname);
        span.setAttribute('server.port', parsedURL.port || undefined);

        try {
          const res = await next(req);
          span.setAttribute('http.response.status_code', 200);
          return res;
        } catch (err) {
          if (err instanceof ConnectError) {
            span.setAttribute(
              'http.response.status_code',
              parseErrorCodeToStatusCode(err.code)
            );
          } else {
            span.setAttribute('http.response.status_code', 500);
          }
          throw err;
        }
      }
    );
  };

function parseErrorCodeToStatusCode(code?: unknown) {
  switch (code) {
    case Code.Canceled:
      return 499;
    case Code.Unknown:
      return 500;
    case Code.InvalidArgument:
      return 400;
    case Code.DeadlineExceeded:
      return 504;
    case Code.NotFound:
      return 404;
    case Code.AlreadyExists:
      return 409;
    case Code.PermissionDenied:
      return 403;
    case Code.ResourceExhausted:
      return 429;
    case Code.FailedPrecondition:
      return 400;
    case Code.Aborted:
      return 409;
    case Code.OutOfRange:
      return 400;
    case Code.Unimplemented:
      return 501;
    case Code.Internal:
      return 500;
    case Code.Unavailable:
      return 503;
    case Code.DataLoss:
      return 500;
    case Code.Unauthenticated:
      return 401;
    default:
      return 500;
  }
}
