import { NgZone } from '@angular/core';
import { SchedulerAction, SchedulerLike, Subscription } from 'rxjs';

class LeaveZoneScheduler implements SchedulerLike {
  constructor(
    private zone: NgZone,
    private scheduler: SchedulerLike
  ) {}

  schedule(
    ...args: [
      work: (this: SchedulerAction<unknown>, state?: unknown) => void,
      delay?: number | undefined,
      state?: unknown,
    ]
  ): Subscription {
    return this.zone.runOutsideAngular(() =>
      // eslint-disable-next-line prefer-spread
      this.scheduler.schedule(...args)
    );
  }

  now(): number {
    return this.scheduler.now();
  }
}

export class EnterZoneScheduler implements SchedulerLike {
  constructor(
    private zone: NgZone,
    private scheduler: SchedulerLike
  ) {}

  schedule(
    ...args: [
      work: (this: SchedulerAction<unknown>, state?: unknown) => void,
      delay?: number | undefined,
      state?: unknown,
    ]
  ): Subscription {
    return this.zone.run(() =>
      // eslint-disable-next-line prefer-spread
      this.scheduler.schedule.apply(this.scheduler, args)
    );
  }

  now(): number {
    return this.scheduler.now();
  }
}

export function leaveZone(
  zone: NgZone,
  scheduler: SchedulerLike
): SchedulerLike {
  return new LeaveZoneScheduler(zone, scheduler);
}

export function enterZone(
  zone: NgZone,
  scheduler: SchedulerLike
): SchedulerLike {
  return new EnterZoneScheduler(zone, scheduler);
}
