import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { OrgService } from '@sites/data-hmm/hmm-orgs';
import { catchError, filter, map, mergeMap, of } from 'rxjs';
import { userServiceActions } from './user.actions';
import { selectAllUsers } from './user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(OrgService)
    private orgService: ObservableClient<typeof OrgService>
  ) {}

  list$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userServiceActions.list),
      concatLatestFrom(() => this.store.select(selectAllUsers)),
      filter(([_, users]) => users.length === 0),
      map(() => userServiceActions.listRefresh())
    );
  });

  listRefresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userServiceActions.listRefresh),
      mergeMap(() =>
        this.orgService.listUsers({}).pipe(
          map(({ results }) =>
            userServiceActions.listSuccess({
              users: results,
            })
          ),
          catchError((error: Error) =>
            of(
              userServiceActions.listFailure({
                error,
              })
            )
          )
        )
      )
    );
  });
}
