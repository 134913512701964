<dashboard-ui-page>
  <dashboard-ui-page-heading> Hello {{ firstName }} </dashboard-ui-page-heading>
  <dashboard-ui-page-subheading>
    You are logged in as
    <span class="tw-font-bold">{{ email }}</span></dashboard-ui-page-subheading
  >
  <div
    class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 xl:tw-grid-cols-3"
  >
    @for (item of menuItems; track $index) {
      <a
        [routerLink]="item.route"
        [title]="item.cta"
        class="tw-flex tw-items-center tw-gap-4 tw-bg-cloud-4 tw-p-4 tw-text-night tw-no-underline tw-transition-[background] tw-duration-150 hover:tw-bg-cloud-2 hover:tw-text-night"
      >
        @if (item.icon) {
          <i class="tw-flex tw-text-lg tw-text-leaf bi {{ item.icon }}"></i>
        }
        @if (item.iconUrl) {
          <img
            class="tw-h-4 tw-w-4"
            [src]="item.iconUrl"
            alt="{{ item.label }}"
            loading="lazy"
          />
        }
        <div class="tw-flex tw-flex-col">
          <h2 class="tw-m-0 tw-p-0 tw-font-heading tw-text-base">
            {{ item.label }}
          </h2>
          <div class="tw-text-sm">{{ item.description }}</div>
        </div>
      </a>
    }
  </div>
</dashboard-ui-page>
