import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ObservableClient } from '@sites/data-connect';
import { catchError, map, of, switchMap } from 'rxjs';
import { audienceTargetDetailServiceAction } from './audience-target-detail.actions';
import { selectAllTargetDetailUsages } from './audience-target-detail.selectors';
import { AudienceService } from '@sites/data-hmm/hmm-ninja';

@Injectable()
export class AudienceTargetDetailEffects {
  constructor(
    private actions$: Actions,
    @Inject(AudienceService)
    private audienceService: ObservableClient<typeof AudienceService>,
    private store: Store
  ) {}

  loadAudienceSuggestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        audienceTargetDetailServiceAction.loadAudienceTargetDetailSuggestions
      ),
      concatLatestFrom(() => this.store.select(selectAllTargetDetailUsages)),

      switchMap(([, exitingTargetDetails]) => {
        if (exitingTargetDetails.length > 0) {
          return of(
            audienceTargetDetailServiceAction.loadAudienceTargetDetailSuggestionsSuccess(
              {
                targetDetailUsages: exitingTargetDetails,
              }
            )
          );
        }
        return this.audienceService.listAudienceTargetDetailUsages({}).pipe(
          map(({ targetDetailUsages }) => {
            return audienceTargetDetailServiceAction.loadAudienceTargetDetailSuggestionsSuccess(
              {
                targetDetailUsages,
              }
            );
          }),
          catchError((error: Error) =>
            of(
              audienceTargetDetailServiceAction.loadAudienceTargetDetailSuggestionsError(
                {
                  error,
                }
              )
            )
          )
        );
      })
    );
  });
}
