import { UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';
import { createActionGroup, props } from '@ngrx/store';

export const serviceWorkerActions = createActionGroup({
  source: 'DashboardStore ServiceWorker',
  events: {
    Updates: props<{ hasUpdates: boolean }>(),
    VersionEvent: props<{ event: VersionEvent }>(),
    UnrecoverableStateEvent: props<{ event: UnrecoverableStateEvent }>(),
  },
});
