import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServiceWorkerKey, ServiceWorkerState } from './service-worker.reducer';

const selectServiceWorkerState =
  createFeatureSelector<ServiceWorkerState>(ServiceWorkerKey);

export const selectServiceWorkerViewModel = createSelector(
  selectServiceWorkerState,
  (state) => {
    return {
      hasUpdate: state.updateAvailable || state.unrecoverableState,
    };
  }
);
