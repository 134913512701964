import { Component, OnInit } from '@angular/core';

import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { ModalComponent } from '../modal/modal.component';
import { menuItemComponentActions } from '../store/feature/feature.actions';
import { selectMenuItemViewModel } from '../store/feature/feature.selectors';

@Component({
  selector: 'dashboard-feature-job-menu-item',
  standalone: true,
  imports: [DialogModule, CommonModule],
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent implements OnInit {
  viewModel$ = this.store.select(selectMenuItemViewModel);

  constructor(
    private store: Store,
    public dialog: Dialog
  ) {}

  ngOnInit() {
    this.store.dispatch(menuItemComponentActions.init());
  }

  onClick() {
    this.dialog.open(ModalComponent, {
      width: '100%',
      maxWidth: '500px',
    });
  }
}
