import { Injectable } from '@angular/core';

import { User } from './user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class StaffGuard {
  private user!: User;

  constructor(private auth: AuthService) {
    this.auth.user$.subscribe((user) => (this.user = user));
  }

  canActivate(): boolean {
    return this.user.isStaff();
  }
}
